import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { isMobile, isTablet } from "react-device-detect"
import { scroller } from "react-scroll"
import _get from "lodash/get"
import LazyLoad from "react-lazy-load"
import cx from "classnames"
import _isEmpty from "lodash/isEmpty"
import { useRouter } from "next/router"

import { getConfig } from "@/constants/config"
import useWindowResize from "@/hooks/useWindowResize"
import usePrevious from "@/hooks/usePrevious"

import {
  addADALink,
  replaceSearchUrl,
  sanitizeText,
  retainSearchQueryParams,
  getSortItemBasedOnURL,
  getAllFilterQueries as getFacetsFromUrl,
  customSanitizeInnerHtml,
} from "@/utils/helper"

import {
  scrollToProduct,
  scrollToFirstProduct,
} from "@/components/Search/v1/ProductsTab/helper"
import {
  searchState,
  setSearchFilter,
  setSearchKeyword,
} from "@/store/features/searchSlice"
import {
  getProducts,
  selectProductListState,
} from "@/store/features/productListSlice"
import { selectAuthState } from "@/store/features/authSlice"

import sortStyles from "@/components/ProductList/v2/Sort/index.module.scss"
import filterStyles from "@/components/ProductList/v2/Filter/index.module.scss"

import InspirationTile from "@/components/Shared/Search/v1/InspirationTile"
import StickyNavHeader from "@/components/ProductList/v2/ProductListView/StickyNav/stickyNavHeader"
import Pagination from "@/components/ProductList/v2/ProductListView/Pagination"
import Filter from "@/components/ProductList/v2/Filter"

import productListViewStyle from "@/components/ProductList/v2/ProductListView/index.module.scss"
import inspirationTileStyles from "@/components/Shared/Search/v1/InspirationTile/index.module.scss"
import inspirationPageStyle from "@/components/Search/v1/InspirationTab/index.module.scss"

const InspirationTab = ({
  page = "",
  type = "",
  searchKeyword = "",
  totalResults = 0,
  displayShare,
  displayPrint,
  isLandscape,
  pageId,
  fquery,
  knowledgeArticleUrl,
  translate,
  staticTexts = {},
  onSearch = () => {},
  productsPerPage,
  totalSearchResults,
  marketParamValues = [],
  profileName = "",
  lwAppName = "",
  isSearchTab = true,
  isFaq = false,
  showTotal = true,
}) => {
  const [width] = useWindowResize()
  const dispatch = useDispatch()
  const selectRow = false
  const [showFilter, setShowFilter] = useState(width >= 1024 ? true : false)
  const [rowCols, setRowCols] = useState(width < 1024 ? 1 : 3)
  const [curRows, setCurRows] = useState(30)
  const [start, setStart] = useState(0)
  const filterModalWindow = useRef(null)
  const [selectedSort, setSelectedSort] = useState({})
  const [filterQueries, setFilterQueries] = useState([])
  const [siteName, setSiteName] = useState("")
  const [dataPopulated, setDataPopulated] = useState(false)
  const [scrollToSku, setScrollToSku] = useState(
    sessionStorage?.plpScrollSku ?? ""
  )
  const [isLoadMore, setIsLoadMore] = useState(false)

  useEffect(() => {
    const selectedSortItem = getSortItemBasedOnURL(staticTexts, {
      name: "Featured",
    })
    setSelectedSort(selectedSortItem)
  }, [])

  const [isFusionLoaded, setIsFusionLoaded] = useState(false)

  const prevRows = usePrevious(curRows)
  const SEARCH = window && window.location.search
  const router = useRouter()

  const { searchDetails = {} } = useSelector(searchState)
  const { fusion: searchFusion = {} } = useSelector(selectProductListState).plp
  const {
    data = [],
    total,
    facets = {},
    facetLabels,
    colors,
  } = useSelector(selectProductListState).plp

  const { access_token: accessToken } = useSelector(selectAuthState)
  const retainedFilters =
    searchDetails.keyword === searchKeyword && searchDetails[type]?.filters
      ? searchDetails[type].filters
      : []

  useEffect(() => {
    getConfig().then(config => {
      const brand = _get(config, "general.siteName")
      setSiteName(brand)
    })
  }, [])

  const filterClass = cx({
    "product-listing__filter": true,
    "product-listing__filter--hide": !showFilter,
    "product-listing__filter--modal": showFilter,
  })

  const tileClass = cx({
    "product-listing__tile-col": true,
    "product-listing__tile-col--full-width": !showFilter,
  })

  const sorts = [
    { name: staticTexts?.featured },
    { name: staticTexts?.atoz, query: "title_s+asc" },
    { name: staticTexts?.ztoa, query: "title_s+desc" },
  ]

  const executeScroll = () => {
    scroller.scrollTo("products-tab", {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuart",
    })
  }

  useEffect(() => {
    switch (true) {
      case width > 1024:
        setRowCols(3)
        break
      case width < 668:
        setRowCols(2)
        break
      default:
        setRowCols(3)
    }
  }, [isLandscape, width])

  useEffect(() => {
    if (accessToken && filterQueries != null) {
      // will trigger for faq details page
      if (pageId) {
        generateListingUrl(false, true)
        // will trigger from onLoadMore
      } else if (start) {
        generateListingUrl(isLoadMore, false)
        // Will trigger when user coming back from PDP page
      } else if (start == 0 && scrollToSku) {
        setScrollToSku("")
        generateListingUrl(false, true)
      }
      // When downgrade pagination
      else if (start == 0 && curRows && !!prevRows && prevRows > curRows) {
        generateListingUrl(false, true)
      }
      // will trigger from onRows. And rest of the dependencies
      else if (start == 0 && curRows) {
        generateListingUrl(false, false)
      }
    }
  }, [accessToken, start, curRows, selectedSort, filterQueries, pageId])

  useEffect(() => {
    // To scrollup the product list on back
    if (dataPopulated) scrollToProduct()
  }, [dataPopulated])

  useEffect(() => {
    let filterQuery = []
    if (!_isEmpty(searchFusion) && accessToken && !isFusionLoaded) {
      setIsFusionLoaded(true)
      if (retainedFilters.length > 0) {
        filterQuery = retainedFilters
      } else {
        filterQuery = getFacetsFromUrl(
          { attributeName: "" }, // this arg to avoid inner condition
          searchFusion,
          marketParamValues
        )
        dispatch(setSearchKeyword(searchKeyword))
        dispatch(setSearchFilter({ queries: filterQuery, key: type }))
        retainSearchQueryParams(type)
      }
      if (filterQuery.length) {
        setFilterQueries(filterQuery)
      }
    }
  }, [searchFusion, accessToken])

  useEffect(() => {
    if (pageId) {
      setStart(0)
    }
  }, [pageId])

  const onRows = rows => {
    setStart(0)
    setCurRows(rows)
  }

  const onLoadMore = () => {
    setStart(start + curRows)
    setIsLoadMore(true)
  }

  const onSort = selectedSort => {
    setStart(0)
    replaceSearchUrl("sort", selectedSort.query)
    if (!isFaq) replaceSearchUrl("tab", type)
    retainSearchQueryParams(type)

    setSelectedSort(selectedSort)
  }
  const generateListingUrl = (update, enableScroll = true, queries) => {
    let callStart = 0

    if (!queries && retainedFilters.length > 0) {
      setFilterQueries(retainedFilters)
      queries = retainedFilters
    } else {
      queries = filterQueries
      callStart = start
      dispatch(setSearchKeyword(searchKeyword))
      dispatch(setSearchFilter({ queries: filterQueries, key: type }))
    }
    if (pageId && SEARCH) {
      const url = window.location.pathname
      const articlePathname = url.split("/").pop()
      router.push(`${url.replace(`/${articlePathname}`, "")}${SEARCH}`)
      return
    }
    const fq = [fquery]
    queries.forEach(q => {
      const tag = `{!tag="${q.facet}"}`
      if (!q.facet.match(/^\*{2}/)) {
        fq.push(
          encodeURIComponent(`${tag + q.facet}:("${q.value.join('" OR "')}")`)
        )
        return
      }
      fq.push(
        `${q.facet.replace(/^\*{2}/, "")}:("${encodeURIComponent(
          q.value.join('","')
        )}")`
      )
    })

    const query = {
      fq,
      rows: curRows,
      start: isLoadMore ? callStart : 0,
    }
    if (selectedSort.name !== staticTexts.featured) {
      if (selectedSort.name) {
        query.sort = selectedSort.query
      }
    }

    const fl = [
      "id",
      "url_s",
      "urlName_s",
      "title_s",
      "pageTitle_s",
      "eyeBrowText_s",
      "thumbNailImage_s",
      "searchDescription_s",
      "layoutItems.Article_Type__c.value_s",
      "layoutItems.KM_Article_Content__c.value_s",
      "layoutItems.KBNA_3C_Image_Video__c.label_s",
      "layoutItems.KBNA_3C_Image_Video__c.value_s",
      "layoutItems.Article_Summary__c.value_s",
    ]

    const queryParams = {
      q: searchKeyword,
      fl: fl.join(","),
      ...query,
      displaytype: "search",
      update,
      isFaq,
    }
    // for handling support/help-and-faq
    if (profileName) {
      queryParams["profileName"] = profileName
      queryParams["collections"] = lwAppName.toLowerCase()
      queryParams["q"] = "*:*"
      queryParams["displaytype"] = ""
    }
    // for handling support/help-and-faq details page
    if (pageId) {
      queryParams["fq"] = `urlName_s:("${pageId}")`
      queryParams["start"] = 0
    }
    if (enableScroll) setDataPopulated(false)
    dispatch(getProducts({ ...queryParams }))
      .then(() => {
        if (enableScroll) setDataPopulated(true)
      })
      .catch(() => {
        if (enableScroll) setDataPopulated(false)
      })
  }

  if (pageId && data[0]) {
    addADALink(
      document.getElementsByClassName("knowledge-article-detail-section")[0],
      { translate: translate }
    )
  }

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    document.getElementById(`kf-product-list-tile-details-0`)?.focus()
  }

  useEffect(() => {
    if (showFilter && (width < 992 || isMobile || isTablet)) {
      filterModalWindow?.current?.focus()
    } else if (!showFilter && (width < 992 || isMobile || isTablet)) {
      document.getElementById("showFilter")?.focus()
    }
  }, [showFilter])
  /**
   * Adds two numbers together.
   * @param {e} e The first number.
   */
  function handleKeyPress(e) {
    if (width < 992) {
      const focusElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), button:not([disabled]) [role="button]:not([hidden=true])'
      const focusContent =
        filterModalWindow.current.querySelectorAll(focusElements)
      const firstElem = focusContent[0]
      const lastElem = focusContent[focusContent.length - 1].disabled
        ? focusContent[focusContent.length - 2]
        : focusContent[focusContent.length - 1]
      if (e.key === "Escape") {
        setShowFilter(false)
      }
      if (e.key === "Tab") {
        if (
          e.shiftKey &&
          (document.activeElement === firstElem ||
            document.activeElement === filterModalWindow.current)
        ) {
          e.preventDefault()
          lastElem.focus()
        }
        if (!e.shiftKey && document.activeElement === lastElem) {
          e.preventDefault()
          firstElem.focus()
        }
      }
    }
  }
  const handleFilter = queries => {
    setIsLoadMore(false)
    dispatch(setSearchKeyword(searchKeyword))
    dispatch(setSearchFilter({ queries, key: type }))
    setFilterQueries(queries)
    scrollToFirstProduct(isFaq)
  }

  return (
    <div
      className={`${productListViewStyle?.productListWrapper}  ${sortStyles.productListingSearchWrapper} ${filterStyles.productListingFilterWrapper} ${inspirationTileStyles.inspirationTileWrapper} ${inspirationPageStyle.inspirationWrapper}`}
    >
      <div className="product-listing">
        <StickyNavHeader
          showFilter={showFilter}
          rowCols={rowCols}
          setShowFilter={setShowFilter}
          setRowCols={setRowCols}
          staticTexts={staticTexts}
          sorts={sorts}
          selectedSort={selectedSort}
          handleSort={onSort}
          totalResults={totalSearchResults}
          isSearchTab={isSearchTab}
          searchKeyword={searchKeyword}
          onSearch={onSearch}
          page={page}
          type={type}
          pageId={pageId}
          showTotal={showTotal}
          isFaq={isFaq}
        />
        <div className="container kf-react-plp-container product-listing__tiles">
          <div className="product-listing__row d-block">
            <div className="filter-col">
              {
                <div className={filterClass} aria-modal ref={filterModalWindow}>
                  <div
                    className={`product-listing__filter--sticky`}
                    aria-hidden={!showFilter}
                    onKeyDown={handleKeyPress}
                  >
                    {facets && (
                      <Filter
                        tabIndex="0"
                        hidePriceRange
                        page={page || ""}
                        texts={staticTexts}
                        facetData={facets}
                        facetLabels={facetLabels}
                        currFilters={filterQueries}
                        onFilter={handleFilter}
                        sort={sorts}
                        selectedSort={selectedSort}
                        onSortChange={onSort}
                        displayShare={true}
                        displayPrint={true}
                        close={setShowFilter}
                        colors={colors}
                        handleScroll={executeScroll}
                        addToUrl={siteName ?? false}
                        showFilter={showFilter}
                        showSort={width <= 991}
                        selectedFilterCount={
                          filterQueries &&
                          filterQueries.reduce((acc, item) => {
                            return acc + item.value.length
                          }, 0)
                        }
                        totalResults={total}
                        isLandscape={isLandscape}
                        type={type || "Inspiration"}
                        isFaq={isFaq}
                        wrapperClass={".inspiration-tile-box"}
                      />
                    )}
                  </div>
                </div>
              }
              <div className={tileClass}>
                {pageId && data[0] && (
                  <div
                    className={`row row-cols-${isMobile ? 1 : 9}`}
                    role="list"
                  >
                    <div className="knowledge-article-detail-section">
                      <h3
                        className="article-type"
                        role="heading"
                        aria-level="3"
                      >
                        {" "}
                        {_get(
                          data[0],
                          "layoutItems.Article_Type__c.value_s",
                          ""
                        )}
                      </h3>
                      <h1 className="title" role="heading" aria-level="1">
                        {_get(data[0], "title_s", "")}
                      </h1>
                      <div className="col-12 px-1">
                        {customSanitizeInnerHtml(
                          data[0]["layoutItems.KM_Article_Content__c.value_s"]
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!pageId && (
                  <div className={`row row-cols-${rowCols}`}>
                    {data.map((element, i) => {
                      let tileData = {}
                      if (type === "Help") {
                        const id = element.id ?? ""
                        const articlePath = element.urlName_s ?? ""
                        tileData = {
                          id: id,
                          contentUrl: `${knowledgeArticleUrl}/${articlePath}`,
                          categoryName: _get(
                            element,
                            "layoutItems.Article_Type__c.value_s",
                            ""
                          ),
                          title: element.pageTitle_s ?? element.title_s,
                          subTitle: "",
                          description: sanitizeText(
                            _get(
                              element,
                              "layoutItems.Article_Summary__c.value_s",
                              ""
                            )
                          ),
                          imageUrl: "",
                        }
                      } else {
                        tileData = {
                          contentUrl: _get(element, "url_s", ""),
                          categoryName: _get(element, "eyeBrowText_s", ""),
                          title: element.pageTitle_s ?? element.title_s,
                          subTitle: "",
                          description: sanitizeText(
                            _get(element, "searchDescription_s", "")
                          ),
                          imageUrl: _get(element, "thumbNailImage_s", ""),
                        }
                      }
                      if (
                        type === "Help" &&
                        element[
                          "layoutItems.KBNA_3C_Image_Video__c.label_s"
                        ] === "Image/Video"
                      ) {
                        const imgElement =
                          element[
                            "layoutItems.KBNA_3C_Image_Video__c.value_s"
                          ] ?? ""
                        if (imgElement) {
                          const div = document.createElement("div")
                          div.innerHTML = imgElement
                          tileData.imageUrl = div.firstChild.src ?? ""
                        }
                      }
                      const isFocusTo = selectRow
                        ? (start > 0 && start === i) ||
                          (curRows > 30 && start === 0
                            ? i === curRows - 30
                            : start >= 30
                            ? i === start + 1
                            : i === 0)
                          ? true
                          : false
                        : false
                      return (
                        <React.Fragment key={i}>
                          <div className="col-12" key={i}>
                            <div
                              className={`col-12 px-0 inspiration-tile-box ${
                                isFocusTo ? "focussed" : ""
                              }`}
                              id={`kf-inspiration-${i}`}
                              role="listitem"
                              tabIndex="0"
                            >
                              {i < 3 ? (
                                <InspirationTile
                                  id={`kf-inspiration-tile-${i}`}
                                  data={tileData}
                                  texts={staticTexts}
                                  lite={!isLandscape ? rowCols === 2 : false}
                                  type={type}
                                  highlightText={searchKeyword}
                                  page={page}
                                />
                              ) : (
                                <LazyLoad className="kf-lazyload-wrapper">
                                  <InspirationTile
                                    id={`kf-inspiration-tile-${i}`}
                                    data={tileData}
                                    texts={staticTexts}
                                    lite={!isLandscape ? rowCols === 2 : false}
                                    type={type}
                                    highlightText={searchKeyword}
                                    page={page}
                                  />
                                </LazyLoad>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                )}
                {!pageId && (
                  <Pagination
                    displayPrint={displayPrint}
                    staticTexts={staticTexts}
                    displayShare={displayShare}
                    curRows={curRows}
                    productsPerPage={productsPerPage}
                    totalResults={total}
                    onRows={onRows}
                    handleBackToTop={handleBackToTop}
                    width={width}
                    isSearchTab={true}
                    page={page}
                    type={type}
                    data={data}
                    onLoadMore={onLoadMore}
                    removeLoadAnimation={isFaq}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InspirationTab
