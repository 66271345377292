import React, { useEffect } from "react"
import Highlight from "@/components/core/Highlight/Highlight"
import { sanitizeText, sanitizeTextForAnalytics } from "@/utils/helper"

import styles from "@/components/Shared/Search/v1/InspirationTile/index.module.scss"

import noImage from "@/public/images/image-missing.png"
import Link from "next/link"

const InspirationTile = props => {
  const { data, type, page, highlightText, id, onToggle = () => {} } = props

  const handleImageError = e => {
    e.target.src = noImage.src
  }

  useEffect(() => {
    window.wow?.sync()
  }, [])

  const getProductNavigationAnalyticsForHelpResults = (
    contentUrl,
    categoryName
  ) => {
    return JSON.stringify({
      eventAction: "search results product navigation",
      eventName: "search results product navigation",
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${sanitizeTextForAnalytics(
        categoryName
      )}:${sanitizeTextForAnalytics(data.title)}`,
      internalLinkPosition: "search results",
      internalLinkType: "search results:help tiles",
      internalLinkZoneName: "search results:help:main content area",
      internalLinkURL: contentUrl
        ? (contentUrl.indexOf("http") === -1 ? window.location.origin : "") +
          contentUrl
        : "n/a",
      clickInternalLinks: "true",
    })
  }

  const getProductNavigationAnalytics = data => {
    const { contentUrl, categoryName } = data
    let alternateType
    if (type === "help" && page === "article") {
      alternateType = "support:help and faq"
    }
    if (type === "help" && page !== "article") {
      return getProductNavigationAnalyticsForHelpResults(
        contentUrl,
        categoryName
      )
    }
    return JSON.stringify({
      eventAction: alternateType
        ? alternateType + ":article list:article"
        : "search results product navigation",
      eventName: alternateType
        ? alternateType + ":article list:article"
        : "search results product navigation",
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: sanitizeTextForAnalytics(data.title),
      internalLinkPosition: alternateType || "search results",
      internalLinkType: alternateType
        ? alternateType + ":" + sanitizeTextForAnalytics(categoryName)
        : `search results:${
            type === "resource" ? "resources" : type || "inspiration"
          } tiles`,
      internalLinkZoneName: alternateType
        ? alternateType + ":article list"
        : `search results:${
            type === "resource" ? "resources" : type || "inspiration"
          }` + ":main content area",
      internalLinkURL: contentUrl
        ? (contentUrl.indexOf("http") === -1 ? window.location.origin : "") +
          contentUrl
        : "n/a",
      clickInternalLinks: "true",
    })
  }

  return (
    <div className={styles.inspirationTileWrapper}>
      <div className="inspiration-tile">
        <Link
          id={id}
          tabIndex="-1"
          className="inspiration-tile__link gbh-data-layer"
          href={data.contentUrl}
          data-gbh-data-layer={getProductNavigationAnalytics(data)}
          onClick={onToggle}
        >
          <div
            className={`inspiration-tile__container no-gutters wow animate__fadeInUp`}
          >
            <div className="inspiration-tile__left-section no-gutters">
              <div
                className={`inspiration-tile__left-section-container no-gutters`}
              >
                <div className="inspiration-tile__left-section-container__first-coloumn">
                  <div className="inspiration-tile__title">
                    <span className="inspiration-tile__category-name">
                      {data.categoryName}
                    </span>
                    <h3 className="inspiration-tile__article-title">
                      <Highlight
                        text={sanitizeText(data.title)}
                        highlight={highlightText}
                      />
                    </h3>
                    {data.subTitle && (
                      <h3 className="inspiration-tile__article-sub-title">
                        <Highlight
                          text={sanitizeText(data.subTitle)}
                          highlight={highlightText}
                        />
                      </h3>
                    )}
                  </div>
                </div>
                <div className="inspiration-tile__left-section-container__second-coloumn">
                  <div className="inspiration-tile__description">
                    <Highlight
                      text={sanitizeText(data.description)}
                      highlight={highlightText}
                    />
                  </div>
                </div>
              </div>
            </div>
            {data.imageUrl ? (
              <div className="inspiration-tile__right-section no-gutters">
                <img
                  className={`inspiration-tile__img`}
                  src={data.imageUrl ? data.imageUrl : noImage.src}
                  alt="inspiration-image"
                  onError={handleImageError}
                />
              </div>
            ) : (
              <div className="inspiration-tile__right-section no-gutters">
                <img
                  className={`inspiration-tile__img`}
                  src={noImage?.src}
                  alt="inspiration-image"
                  onError={handleImageError}
                />
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default InspirationTile
