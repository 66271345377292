import React, { useState } from "react"
import {
  sanitizeTextForAnalytics,
  handleZipDownload,
  organizeData,
} from "@/utils/helper"
import { useSelector } from "react-redux"
import { selectGenericState } from "@/store/features/genericSlice"
import PartsViewer from "@/components/PartsViewer/v1/PartsViewer"
import PartsViewerV2 from "@/components/PartsViewer/v2/PartsViewer"
import Button from "@/components/core/Button/Button"
import Accordion from "@/components/core/Accordion/Accordion"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import { technicalSpecsServicePartsAnalytics } from "@/components/Search/v1/TechnicalTile/analytics"
import pdfIcon from "@/public/icons/pdf-grey.svg"
import twoDPlan from "@/public/icons/2D-plan.svg"
import twoDFront from "@/public/icons/2D-front.svg"
import twoDSide from "@/public/icons/2D-side.svg"
import threeDIcon from "@/public/icons/3D.svg"
import threeDBIM from "@/public/icons/3D-BIM.svg"
import cutoutIcon from "@/public/icons/cutout.svg"
import downloadIcon from "@/public/icons/download-dark-gray.svg"
import partsIcon from "@/public/icons/parts-diagram.svg"

const TechAccordion = props => {
  const { data, designFiles, productInfo, sku, staticTexts, page } = props
  const [collapse, setCollapse] = useState({
    design: false,
    technical: false,
    install: false,
  })
  const [dwgFiles, setDwgFiles] = useState([])
  const [partsModal, setPartsModal] = useState({
    show: false,
    id: "",
    item: {},
  })
  const [loading, setLoading] = useState(false)
  const handleCollapse = (type, state) => {
    setCollapse({ ...collapse, [type]: state })
  }

  const techFiles = []
  const installFiles = []
  const partsFiles = []
  const pdfPartsFiles = []

  // check for technical files
  if (data["ProductResource.SpecPDFFileName.resourceFullWebURL_ss"]) {
    techFiles.push(
      ...organizeData(
        data["ProductResource.SpecPDFFileName.resourceFullWebURL_ss"],
        data["ProductResource.SpecPDFFileName.resourceTitle_ss"],
        staticTexts.specsSheet,
        "PDF",
        "spec-file"
      )
    )
  }

  if (data["ProductResource.EnvironProdDecl.resourceFullWebURL_ss"]) {
    techFiles.push(
      ...organizeData(
        data["ProductResource.EnvironProdDecl.resourceFullWebURL_ss"],
        data["ProductResource.EnvironProdDecl.resourceTitle_ss"],
        staticTexts.envDeclaration,
        "PDF",
        "env-dec"
      )
    )
  }

  // check for installation & support files
  if (data["ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss"]) {
    installFiles.push(
      ...organizeData(
        data["ProductResource.InstallationWithoutSPPDF.resourceFullWebURL_ss"],
        data["ProductResource.InstallationWithoutSPPDF.resourceTitle_ss"],
        staticTexts.installationGuide,
        "PDF",
        "install-guide"
      )
    )
  }

  if (data["ProductResource.HomeownersGuide.resourceFullWebURL_ss"]) {
    installFiles.push(
      ...organizeData(
        data["ProductResource.HomeownersGuide.resourceFullWebURL_ss"],
        data["ProductResource.HomeownersGuide.resourceTitle_ss"],
        staticTexts.homeOwnerGuide,
        "PDF",
        "owners-guide"
      )
    )
  }

  if (data["ProductResource.InteractiveSVG.resourceName_ss"]) {
    partsFiles.push(
      ...organizeData(
        data["ProductResource.InteractiveSVG.resourceName_ss"],
        data["ProductResource.InteractiveSVG.resourceTitle_ss"],
        staticTexts.partsDiagram,
        "PART"
      )
    )
  }

  if (data["ProductResource.PartsPDFFileName.resourceFullWebURL_ss"]) {
    pdfPartsFiles.push(
      ...organizeData(
        data["ProductResource.PartsPDFFileName.resourceFullWebURL_ss"],
        data["ProductResource.PartsPDFFileName.resourceTitle_ss"],
        staticTexts.partsDiagram,
        "PART"
      )
    )
  }

  const handleDesignSelect = (checked, file, name) => {
    file.actualName = sanitizeTextForAnalytics(name)
    if (checked) setDwgFiles([...dwgFiles, file])
    else setDwgFiles(dwgFiles.filter(item => item.id !== file.id))
  }

  const handleTechInfoDownload = file => {
    try {
      window.open(file.url)
      addDownloadFilesAnalytics("technical", "success", "", file)
    } catch (error) {
      addDownloadFilesAnalytics("technical", "failure", "download failed")
    }
  }

  const getTechnicalFileName = file => {
    return sanitizeTextForAnalytics(
      `${file.title?.trim()?.toLowerCase()}.${file.type}`
    )
  }

  const getInstallationFileNames = file => {
    return sanitizeTextForAnalytics(
      `${file.title?.trim()?.toLowerCase()}.${file.type}`
    )
  }

  const handleDesignSelectAll = checked => {
    if (checked) {
      const selected = []
      designFiles.twoDimension.plan.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`2d plan:${obj.type}`)
        selected.push(obj)
      })
      designFiles.twoDimension.front.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`2d front:${obj.type}`)
        selected.push(obj)
      })
      designFiles.twoDimension.side.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`2d side:${obj.type}`)
        selected.push(obj)
      })
      designFiles.threeDimension.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`3d file:${obj.type}`)
        selected.push(obj)
      })
      designFiles.bim.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`bim:${obj.type}`)
        selected.push(obj)
      })
      designFiles.cutout.forEach(obj => {
        obj.actualName = sanitizeTextForAnalytics(`cutout template:${obj.type}`)
        selected.push(obj)
      })
      setDwgFiles(selected)
    } else setDwgFiles([])
  }

  const getDesignFileNames = () => {
    const fileTypesString = []
    if (dwgFiles?.length) {
      dwgFiles.forEach(fileObj => {
        fileTypesString.push(fileObj.actualName)
      })
    }
    return fileTypesString.join(":") || "n/a"
  }
  const getDesignFileUrl = () => {
    const fileUrlString = dwgFiles.map(fileObj => fileObj?.url?.toLowerCase())
    return fileUrlString?.join(":") || "n/a"
  }

  const handlePdfDownload = (e, link, title) => {
    e.stopPropagation()
    let errMsg
    try {
      window.open(link.trim(), "_blank")
    } catch (err) {
      errMsg = err.message
    }
    addDownloadFilesAnalytics(title, errMsg, link.trim())
  }

  const addDownloadFilesAnalytics = (type, status, message, file) => {
    let eventInfo
    if (type === "design") {
      eventInfo = getDesignFilesDownloadEventInfo(status, message)
    } else if (type === "technical") {
      eventInfo = getTechnicalInfoDownloadEventInfo(status, message, file)
    } else if (type === "installation") {
      eventInfo = getInstallationSupportDownloadEventInfo(status, message, file)
    }
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const pI = JSON.parse(productInfo).productInfo
    window.adobeDataLayer.push({
      event: "cmp:click",
      eventInfo,
      page,
      productInfo: pI,
    })
  }

  const getTechnicalTabAccordionAnalytics = (collapseBoolean, type) => {
    const alternateName =
      page === "technicalspecs" ? "technical specs" : "search results"
    return JSON.stringify({
      eventAction: `${alternateName}${
        alternateName === "search results" ? ":technical" : ""
      }:main content area:${type} ${collapseBoolean ? "collapse" : "expand"}`,
      eventName: `${alternateName}${
        alternateName === "search results" ? ":technical" : ""
      }:main content area:${type} ${collapseBoolean ? "collapse" : "expand"}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName:
        "accordian " + (collapseBoolean ? "collapse" : "expand"),
      internalLinkPosition: alternateName,
      internalLinkType: alternateName + ":" + type,
      internalLinkZoneName: `${alternateName}${
        alternateName === "search results" ? ":technical" : ""
      }:main content area`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    })
  }

  const getDesignFilesDownloadEventInfo = (status, message) => {
    if (page === "technicalspecs") {
      return {
        eventAction: "technical specs:main content area:design files:download",
        eventName: "technical specs:main content area:design files:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: "download",
        internalLinkPosition: "tech specs",
        internalLinkType: "technical specs:design files",
        internalLinkZoneName: "technical specs:main content area",
        internalLinkURL: "n/a",
        clickInternalLinks: "true",
        fileNameAndFileType: getDesignFileNames(),
      }
    } else {
      return {
        eventAction: "search results:technical:main content area:download",
        eventName: "search results:technical:main content area:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: "download",
        internalLinkPosition: "search results",
        internalLinkType: "search results:design files",
        internalLinkZoneName: "search results:technical:main content area",
        internalLinkURL: getDesignFileUrl(),
        clickInternalLinks: "true",
        fileNameAndFileType: getDesignFileNames(),
      }
    }
  }

  const getTechnicalInfoDownloadEventInfo = (status, message, file) => {
    if (page === "technicalspecs") {
      return {
        eventAction:
          "technical specs:main content area:technical info:download",
        eventName: "technical specs:main content area:technical info:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: getTechnicalFileName(file),
        internalLinkPosition: "tech specs",
        internalLinkType: "technical specs:technical info",
        internalLinkZoneName: "technical specs:main content area",
        internalLinkURL: `${file.url}`,
        clickInternalLinks: "true",
        fileNameAndFileType: getTechnicalFileName(file),
      }
    } else {
      return {
        eventAction: "search results:technical:main content area:download",
        eventName: "search results:technical:main content area:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: getTechnicalFileName(file),
        internalLinkPosition: "search results",
        internalLinkType: "search results:technical info",
        internalLinkZoneName: "search results:technical:main content area",
        internalLinkURL: `${file.url}`,
        clickInternalLinks: "true",
        fileNameAndFileType: getTechnicalFileName(file),
      }
    }
  }

  const getInstallationSupportDownloadEventInfo = (
    status,
    message,
    file,
    url
  ) => {
    if (page === "technicalspecs") {
      return {
        eventAction:
          "technical specs:main content area:installation and support:download",
        eventName:
          "technical specs:main content area:installation and support:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: getInstallationFileNames(file),
        internalLinkPosition: "technical specs",
        internalLinkType: "technical specs:download",
        internalLinkZoneName: "technical specs:installation and support",
        internalLinkURL: `${file.url}`,
        clickInternalLinks: "true",
        fileNameAndFileType: getInstallationFileNames(file),
      }
    } else {
      return {
        eventAction: "search results:technical:main content area:download",
        eventName: "search results:technical:main content area:download",
        eventType: "navigation",
        eventMsg: message || "n/a",
        eventStatus: status || "n/a",
        internalLinkName: getInstallationFileNames(file),
        internalLinkPosition: "search results",
        internalLinkType: "search results:installation & service parts",
        internalLinkZoneName: "search results:technical:main content area",
        internalLinkURL: `${file.url}`,
        clickInternalLinks: "true",
        fileNameAndFileType: getInstallationFileNames(file),
      }
    }
  }

  const handleDownload = (sel, type) => {
    setLoading(true)
    const files = sel.map(({ url = "" }) => url)
    if (files?.length === 1) {
      try {
        window.open(files[0])
        addDownloadFilesAnalytics(type, "success")
      } catch (error) {
        addDownloadFilesAnalytics(type, "failure", "download failed")
      }
      setLoading(false)
    } else if (files?.length > 1) {
      handleZipDownload(
        files,
        sku,
        () => {
          setLoading(false)
          addDownloadFilesAnalytics(type, "success")
        },
        () => {
          setLoading(false)
          addDownloadFilesAnalytics(type, "failure", "download failed")
        }
      )
    }
  }
  const designFilesLength =
    designFiles.twoDimension.plan.length +
    designFiles.twoDimension.front.length +
    designFiles.twoDimension.side.length +
    designFiles.threeDimension.length +
    designFiles.bim.length +
    designFiles.cutout.length

  const handleDownloadOfInstallFiles = item => {
    const { url, type, title } = item
    if (url) window.open(url.trim(), "_blank")
    addDownloadFilesAnalytics("installation", "success", "", {
      type,
      title,
      url,
    })
  }

  const closeModal = () => {
    setPartsModal({ show: false, id: "", item: {} })
  }

  const { featureFlags } = useSelector(selectGenericState)
  const {
    MYSQLCONNSTR_ENABLE_PARTS_VIEWER_V2: isPartsViewerV2Enabled = false,
  } = featureFlags
  return (
    <>
      {techFiles.length !== 0 && (
        <Accordion
          isOpen={collapse["technical"]}
          handleOpen={() => handleCollapse("technical", true)}
          handleClose={() => handleCollapse("technical", false)}
          analyticTitleData={getTechnicalTabAccordionAnalytics(
            collapse["technical"],
            "technical info"
          )}
          analyticTitleCustomData={productInfo}
          title={staticTexts.technicalInfo}
        >
          <div
            className="technical-tile__download-section d-flex"
            aria-hidden={!collapse.technical}
          >
            <div className="technical-tile__files --technical-info">
              <section className="d-block p-0">
                {techFiles.map((item, i) => {
                  return (
                    <div key={i} className="technical-tile__file">
                      <span onClick={() => handleTechInfoDownload(item)}>
                        <img alt="" aria-hidden="true" src={downloadIcon.src} />
                        {item.title}
                      </span>
                    </div>
                  )
                })}
              </section>
            </div>
          </div>
        </Accordion>
      )}
      {installFiles.length !== 0 && (
        <Accordion
          isOpen={collapse["install"]}
          handleOpen={() => handleCollapse("install", true)}
          handleClose={() => handleCollapse("install", false)}
          analyticTitleData={getTechnicalTabAccordionAnalytics(
            collapse["install"],
            staticTexts.installationSupport.toLowerCase()
          )}
          analyticTitleCustomData={productInfo}
          title={staticTexts.installationSupport}
        >
          <div
            className="technical-tile__download-section technical-section-main"
            aria-hidden={!collapse.install}
          >
            {installFiles.length ? (
              <p className="technical-title_sub_title">{staticTexts.guides}</p>
            ) : null}
            {installFiles.length
              ? installFiles.map((item, i) => {
                  return (
                    <div
                      className="technical-tile__files --technical-info m-right"
                      key={i}
                    >
                      <section>
                        <span
                          className="technical-tile__file-type"
                          role={"link"}
                          onClick={() => handleDownloadOfInstallFiles(item)}
                        >
                          <img src={pdfIcon.src} alt="pdfdownload" />
                          {item.title}
                        </span>
                      </section>
                    </div>
                  )
                })
              : null}
            {partsFiles.length ? (
              <p className="technical-title_sub_title part_tile_download">
                {staticTexts.partsDiagram}
              </p>
            ) : null}
            {partsFiles.length
              ? partsFiles.map((item, i) => (
                  <div
                    className="technical-tile__files --technical-info m-right"
                    key={i}
                  >
                    <section>
                      <span
                        className="technical-tile__file-type"
                        onClick={() => {
                          setPartsModal({
                            show: true,
                            id: item.id,
                            item: item,
                          })
                          technicalSpecsServicePartsAnalytics(
                            "technical specs",
                            "installation and support",
                            productInfo
                          )
                        }}
                      >
                        <img src={partsIcon.src} alt="parts" /> {item.title}
                      </span>
                    </section>
                  </div>
                ))
              : null}
            {pdfPartsFiles.length
              ? pdfPartsFiles.map((item, i) => (
                  <div
                    className="technical-tile__files --technical-info m-right"
                    key={i}
                  >
                    <section>
                      <span
                        className="technical-tile__file-type"
                        onClick={e => handlePdfDownload(e, item.id, item.title)}
                      >
                        <img src={partsIcon.src} alt="parts" /> {item.title}
                      </span>
                    </section>
                  </div>
                ))
              : null}
          </div>
        </Accordion>
      )}
      {!(
        designFiles.twoDimension.plan.length === 0 &&
        designFiles.twoDimension.front.length === 0 &&
        designFiles.twoDimension.side.length === 0 &&
        designFiles.threeDimension.length === 0
      ) && (
        <Accordion
          isOpen={collapse["design"]}
          handleOpen={() => handleCollapse("design", true)}
          handleClose={() => handleCollapse("design", false)}
          analyticTitleData={getTechnicalTabAccordionAnalytics(
            collapse["design"],
            "design files"
          )}
          analyticTitleCustomData={productInfo}
          title={staticTexts.designFiles}
        >
          <div
            className="technical-tile__download-section"
            aria-hidden={!collapse.design}
          >
            <section className="technical-tile__select-all">
              <Checkbox
                tabIndex={collapse["design"] ? 0 : -1}
                value={staticTexts.selectAll}
                checked={
                  designFilesLength > 0 && dwgFiles.length === designFilesLength
                }
                onChange={e => handleDesignSelectAll(e.target.checked)}
                disabled={!designFilesLength}
              />
            </section>
            <Button
              role="button"
              size="small"
              loading={loading}
              onClick={() => handleDownload(dwgFiles, "design")}
              flexible={true}
              label={`${staticTexts.download} ${
                dwgFiles.length > 0
                  ? dwgFiles.length +
                    " " +
                    (dwgFiles.length === 1
                      ? staticTexts.file
                      : staticTexts.files)
                  : ""
              }`}
              disabled={dwgFiles.length === 0}
            />
            <div className="technical-tile__files">
              {designFiles.twoDimension.plan.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={twoDPlan.src} />{" "}
                    {staticTexts.twoDplan}
                  </span>
                  <span className="checkbox-list">
                    {designFiles.twoDimension.plan.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`2D plan ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `2d plan:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
              {designFiles.twoDimension.front.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={twoDFront.src} />{" "}
                    {staticTexts.twoDfront}
                  </span>
                  <span className="checkbox-list">
                    {designFiles.twoDimension.front.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`2D Front ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `2d front:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
              {designFiles.twoDimension.side.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={twoDSide.src} />{" "}
                    {staticTexts.twoDside}
                  </span>
                  <span className="checkbox-list">
                    {designFiles.twoDimension.side.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`2D side ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `2d side:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
              {designFiles.threeDimension.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={threeDIcon.src} />{" "}
                    {staticTexts.threeDfile}
                  </span>
                  <span className="checkbox-list input-group">
                    {designFiles.threeDimension.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`3D File ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `3d file:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
              {designFiles.bim.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={threeDBIM.src} />{" "}
                    {staticTexts.bimFile}
                  </span>
                  <span className="checkbox-list">
                    {designFiles.bim.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`BIM ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `bim:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
              {designFiles.cutout.length > 0 && (
                <section>
                  <span className="technical-tile__file-type">
                    <img alt="" aria-hidden="true" src={cutoutIcon.src} />{" "}
                    {staticTexts.cutoutTemplate}
                  </span>
                  <span className="checkbox-list">
                    {designFiles.cutout.map((item, i) => {
                      return (
                        <div key={i}>
                          <Checkbox
                            tabIndex={collapse["design"] ? 0 : -1}
                            checkBoxAria={`Cutout Template ${item.type}`}
                            value={item.type}
                            checked={
                              dwgFiles.find(f => f.id === item.id)
                                ? true
                                : false
                            }
                            onChange={e =>
                              handleDesignSelect(
                                e.target.checked,
                                item,
                                `cutout template:${item.type}`
                              )
                            }
                          />
                        </div>
                      )
                    })}
                  </span>
                </section>
              )}
            </div>
          </div>
        </Accordion>
      )}
      {partsModal.show &&
        (isPartsViewerV2Enabled ? (
          <PartsViewerV2
            page={page + " results"}
            productId={data["ProductProductNo_s"]}
            partId={partsModal.id}
            showModal={true}
            onClose={closeModal}
            analyticsCustomData={productInfo}
            fileMap={partsFiles}
          />
        ) : (
          <PartsViewer
            page={page + " results"}
            productId={data["ProductProductNo_s"]}
            partId={partsModal.id}
            showModal={partsModal.show}
            onClose={closeModal}
            analyticsCustomData={productInfo}
          />
        ))}
    </>
  )
}

export default TechAccordion
